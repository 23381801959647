import React from 'react';
import './App.css';
import {app} from './Firebase';
import validator from 'validator';
import PasswordStrengthBar from 'react-password-strength-bar';
import { getAuth, signInWithPopup, GoogleAuthProvider,isSignInWithEmailLink, signInWithEmailAndPassword, signInWithEmailLink, sendSignInLinkToEmail} from 'firebase/auth';
import { render } from '@testing-library/react';
import { Helmet } from 'react-helmet';
const { initializeAppCheck, ReCaptchaV3Provider } = require("firebase/app-check");
const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6Ld8xaQeAAAAADrXPHQZquPfJ4MR7ziLXnWjpNRW'),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true
});

const auth = getAuth();
const provider = new GoogleAuthProvider();
var isNewUser;
let message;
let styles;
let resultuser;
function signIn() {
    signInWithPopup(auth, provider)
    .then((result) => {
    }).catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
    });
  }


export const Signinpage = () => {
    const [email, setEmail] = React.useState('');
    const [email2, setEmail2] = React.useState('');
    const [errors, setError] = React.useState('');
    let reemail;
    function emailsubmit(event) {
        event.preventDefault();
        console.log(email2);
        reemail = email2;
        document.getElementsByClassName("overlay")[0].style.display = "none";
    }
    const actionCodeSettings = {
        url: 'https://flashnotes.thisistimnguyen.com/',
        handleCodeInApp: true
    };
    function emailsignin(event){
        event.preventDefault();
        sendSignInLinkToEmail(auth, email, actionCodeSettings)
        .then(() => {
            window.localStorage.setItem('emailForSignIn', email);
            setError("Check your email for a sign in link");
        }).catch((error) => {
            const errorMessage = error.message;
            if(errorMessage == "Firebase: Error (auth/missing-email)."){
                setError("Please enter an email address");
            }
            else if(errorMessage == "Firebase: Error (auth/invalid-email."){
                setError("Please enter a valid email address");
            }
            else {
                setError(errorMessage);
            }
        });
    }
    if (isSignInWithEmailLink(auth, window.location.href)) {
        let email = window.localStorage.getItem('emailForSignIn');
        var link = window.location.href.includes("apiKey");
        console.log(window.location.href);
        if (!email &&  link == true) {
            
            // Overlayon();
            // console.log("jetblue");
            // email = email2;
        }
        signInWithEmailLink(auth, email, window.location.href)
          .then((result) => {
            window.localStorage.removeItem('emailForSignIn');
          })
          .catch((error) => {
              setError("Please try signing in again.");
          });
      }  
    const button ={
        height: "50px",
        display: "block",
        border: "none",
        outline: "none",
        width: "250px",
        transition: ".25s",
        backgroundColor: "#4285F4",
        borderRadius: "10px",
        marginTop: "20px",
        fontSize: "20px",
        color: "white" 
    };

    // function Overlayon() {
    //     const button1 ={
    //         height: "50px",
    //         display: "block",
    //         border: "none",
    //         outline: "none",
    //         width: "315px",
    //         transition: ".25s",
    //         backgroundColor: "#4285F4",
    //         borderRadius: "10px",
    //         marginTop: "20px",
    //         fontSize: "20px",
    //         color: "white"
              
    //     };
    //     return(
    //         <div className='overlay'>
    //         <div style={{display: "block"}} className='overlaycard'>
    //             <h1 style={{marginTop: "0px"}}>Email</h1>
    //             <p style={{marginTop: "-10px"}}>Please enter your email again in order to sign in.</p>
    //             <div className='overlaybox' style={{textAlign: "center", marginLeft: "auto", marginRight: "auto"}}>
    //             <form style={{display: "block"}}>
    //                 <input style={{width: "300px"}} type='email' placeholder='Email' value={email2} onChange={(e) => setEmail2(e.target.value)}/>
    //                 <button className="button1" style={button1} >Sign In</button>
    //             </form>

    //             </div>
    //         </div>
    //     </div>
    //     );
    // }

    // function overlayoff() {
    //     document.getElementsByClassName("overlay")[0].style.display = "none";
    // }
    return (
        <div className='signinoverlay'>
        <Helmet>
            <title>Sign In to FlashNotes</title>
        </Helmet>
            <div className='sidebar'>
                <h1 className='title'>FlashNotes</h1>
            </div>
            <div className='signinside'>
                <div className='signincont'hidden={false}>
                    <h1 className='login titlem'>FlashNotes</h1>
                    <h1 className='login'>Login</h1>
                    <p className='signindesc'>Sign in to get started.</p>
                    <form onSubmit={emailsignin}>
                        <input placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value)}></input>
                        <p>{errors}</p>
                        <button className='emailsignin' style={button} type="submit"> Passwordless Sign In</button>
                    </form>
                    <div className='divider'></div>
                    <button className='googlesignin' style={button} onClick={signIn}>Sign in with Google</button>
                </div>
            </div>
        </div>
    );
}