import React from 'react';
import { useState, useEffect } from "react";
import './App.css';
import {app} from './Firebase';
import { useParams, Link } from "react-router-dom";
import { useAuthState } from 'react-firebase-hooks/auth';
import { getAuth } from 'firebase/auth';
import { doc, getDoc, getFirestore, Timestamp, deleteDoc, setDoc, onSnapshot, orderBy, limit, query, collection } from "firebase/firestore";
import EditorJs from 'react-editor-js'
import Paragraph from '@editorjs/paragraph'
import {MdDelete} from "react-icons/md";
import nestedList from '@editorjs/nested-list'
import Code from '@editorjs/code'
import Header from '@editorjs/header'
import Quote from '@editorjs/quote'
import Marker from '@editorjs/marker'
import Underline from '@editorjs/underline';
import CheckList from '@editorjs/checklist'
import Delimiter from '@editorjs/delimiter'
import InlineCode from '@editorjs/inline-code'
import {useAsyncEffect} from '@react-hook/async'
import {Helmet} from "react-helmet";


const auth = getAuth();
const db = getFirestore(app);
export const EDITOR_JS_TOOLS = {
    list: nestedList,
    code: Code,
    header: Header,
    quote: Quote,
    marker: Marker,
    // checklist: CheckList,
    delimiter: Delimiter,
    underline: Underline,
    inlineCode: InlineCode,
  }
export default function Edit() {
    let params = useParams();
    const [user] = useAuthState(auth);
    const [database, setDatabase] = useState('');
    const [messages, setMessages] = useState('');
    const instanceRef = React.useRef(null);
    const [journals, setJournals] = useState([]);
    let posts = [];
    const [title, setTitle] = useState('');
    const [save, setSave] = useState("Save");
    let posted = (params.public === "private" ) ? "journals" : "posts";
    const button ={
        height: "50px",
        display: "block",
        border: "none",
        outline: "none",
        width: "250px",
        transition: ".25s",
        backgroundColor: "#4285F4",
        borderRadius: "10px",
        marginTop: "20px",
        fontSize: "20px",
        textDecoration: 'none',
        textAlign: "center",
        lineHeight: "50px",
        color: "white" 
    };
    const button2 ={
      height: "30px",
      display: "inline-block",
      border: "none",
      outline: "none",
      margin: "10px",
      width: "fit-content",
      transition: ".25s",
      backgroundColor: "#4285F4",
      borderRadius: "10px",
      marginTop: "20px",
      fontSize: "20px",
      color: "white" 
  };
    const docRef = doc(db, `${params.public}`, `${user.uid}`, `${posted}`, `${params.journalID}`);
     const {userjournal} = useAsyncEffect(async() => {
         const docRef = doc(db, `${params.public}`, `${user.uid}`, `${posted}`, `${params.journalID}`);
         return getDoc(docRef).then((journal) => {
             if (!journal.exists()) setMessages("Journal does not exist");
             setDatabase(journal.data());
             setTitle(journal.data().name);
         });
     }, []);
    console.log(database.entry);
    console.log(title);
    const [privacyset, setPrivacyset] = useState(params.public ? false : true);
    const [privacy, setPrivacy] = useState(params.public);
    class Toggle extends React.Component {
        constructor(props) {
          super(props);
          this.state = {isPrivate: privacyset};
          console.log(privacyset);
          this.handleClick = this.handleClick.bind(this);
        }
        
        handleClick() {
          this.setState(prevState => ({
            isPrivate: !prevState.isPrivate
          }));
          Private(this.state.isPrivate)
          console.log(this.state.isPrivate);
        }
        render() {
          return (
            <button className="setbutton" onClick={this.handleClick} style={button}>
              {privacy}
            </button>
          );
        }
      }
      class Toggle2 extends React.Component {
        constructor(props) {
          super(props);
          this.state = {isPrivate: true};
          this.handleClick = this.handleClick.bind(this);
        }
      
        handleClick() {
          this.setState(prevState => ({
            isPrivate: !prevState.isPrivate
          }));
          Private(this.state.isPrivate)
        }
        render() {
          return (
            <button className="setbutton" onClick={this.handleClick} style={button2}>
              {privacy}
            </button>
          );
        }
      }
      function Private(input) {
        if (input == false) {
            setPrivacy("Public");
            setPrivacyset(false);
        }
        else {
            setPrivacy("Private");
            setPrivacyset(true);
        }
    }
      useEffect(() => {
        const q = query(collection(db, "public", `${user.uid}`, `posts`, `${params.journalID}`, "comments"), orderBy('lastModified', 'desc'), limit(5));
        const load = onSnapshot(q, (snapshot) => {
            snapshot.docs.map(doc => {
                posts.push({...doc.data(), id: doc.id});
            });
        })
        setJournals(posts);
        console.log(journals)
    }, []);
    async function HandleSave() {
        const savedData = await instanceRef.current.save();
        console.log(savedData);
        if (privacy == "Private" && params.public =="public") {
            await setDoc(doc(db, "private", `${user.uid}`, "journals", `${params.journalID}`), {
              name: title,
              entry: savedData,
              lastModified: Timestamp.now(),
              author: user.displayName,
              uid: user.uid,
              picture: user.photoURL,
              user: user.uid
            });
            await deleteDoc(doc(db,  "public", `${user.uid}`, "posts", `${params.journalID}`));
            setSave("Journal Saved");
            setTimeout(() => { window.location.href = `/edit/private/${params.journalID}`;}, 500);
        }
        if (privacy == "Public" && params.public =="private") {
            await setDoc(doc(db, "public", `${user.uid}`, "posts", `${params.journalID}`), {
                name: title,
                entry: savedData,
                lastModified: Timestamp.now(),
                author: user.displayName,
                uid: user.uid,
                picture: user.photoURL,
                user: user.uid
            });
            await deleteDoc(doc(db,  "private", `${user.uid}`, "journals", `${params.journalID}`));
            setSave("Journal Saved");
            setTimeout(() => { window.location.href = `/edit/public/${params.journalID}`;}, 500);
        }
        else {
            await setDoc(doc(db, `${params.public}`, `${user.uid}`, `${posted}`, `${params.journalID}`), {
              name: title,
              entry: savedData,
              lastModified: Timestamp.now(),
              author: user.displayName,
              uid: user.uid,
              picture: user.photoURL,
              user: user.uid
            });
            setSave("Journal Saved");
            setTimeout(() => { setSave("Save");}, 2000);
        }
    }
    async function handledelete() {
        await deleteDoc(doc(db, `${params.public}`, `${user.uid}`, `${posted}`, `${params.journalID}`));
        setSave("File Deleted");
            setTimeout(() => { window.location.href = `/home`;}, 500);
    }
    return (
      <main className='appconts createcont'>
        <Helmet>
            <title>{title}</title>
        </Helmet>        
        <div className="sidepanelleft">
            <div className='sidepanel'>
            <h2 style={{marginTop: "0px", display: "inline-block"}}>Settings</h2>
                <button onClick={handledelete} className="deletebutton">
                         <MdDelete/>
                </button>
            <p>Post Visibility:</p>
            <Toggle/>
            <div className='divider'></div>
            <button className="setbutton" style={button} onClick={HandleSave}>{save}</button>
            </div>
            {params.public == "public" ? 
            <div className='sidepanel'>
              <h2 style={{marginTop: "0px"}}>Comments</h2>
              {journals.map((journal, index) => (
                    <div key={journal.id} style={{display: "block"}}>
                    <div className='divider'></div>
                    <h3 style={{fontSize: "25px", display: "inline-block"}}>{journal.author}</h3>
                    <p className='author'>{journal.lastModified.toDate().toDateString()}</p>
                    <p className='preview'>{ journal.entry }</p>   
                 </div>                
              ))}
              <Link to={`/posts/${user.uid}/${params.journalID}`} style={button}>Click to see more </Link>
            </div> 
            : null}
        </div>
        <div className='headerbar'>
        <form>
            <input className="titleinput" placeholder="Title" value={title} onChange={(e) => setTitle(e.target.value)}></input>
        </form>
        <h2>{messages}</h2>
        <div className="mobilesave">
        <Toggle2/>
        <button className="setbutton" style={button2} onClick={HandleSave}>{save}</button>
        </div>
        {database ? <EditorJs instanceRef={instance => (instanceRef.current = instance)} tools={EDITOR_JS_TOOLS} data={database.entry}/>: <h2>Loading...</h2>}
        </div>
        
        <div className="sidepanelright"></div>
      </main>
    );
  }