import React from 'react';
import { useCallback, useEffect, useState } from "react";
import './App.css';
import {app} from './Firebase';
import { useParams } from "react-router-dom";
import { useAuthState } from 'react-firebase-hooks/auth';
import { getAuth } from 'firebase/auth';
import { doc, getDoc, getFirestore, addDoc, collection, Timestamp, query, onSnapshot, orderBy, limit, startAfter, deleteDoc } from "firebase/firestore";
import Output from 'editorjs-react-renderer';
import {MdDelete} from "react-icons/md";
import {Helmet} from "react-helmet";
const edjsHTML = require("editorjs-html");
const edjsparser = edjsHTML();

const auth = getAuth();
const db = getFirestore(app);
export default function Posts() {
    let params = useParams();
    const [user] = useAuthState(auth);
    const [database, setDatabase] = useState('');
    const [comments, setComments] = useState("");
    const [messages, setMessages] = useState('');
    const [journals, setJournals] = useState([]);
    let posts = [];
    const [lastvisibility, setlastvisibility] = useState({});
    const [save, setSave] = useState('Comment');
    useEffect(() => {
        const docRef = doc(db, "public", `${params.userId}`, `posts`, `${params.postId}`);
        getDoc(docRef).then((journal) => {
            if (!journal.exists()) setMessages("Journal does not exist");
            setDatabase(journal.data());
        });
    })
    async function HandleSave(e) {
        const newdocRef = addDoc(collection(db, "public", `${params.userId}`, `posts`, `${params.postId}`, "comments"), {
            entry: comments,
            lastModified: Timestamp.now(),
            author: user.displayName,
            uid: user.uid,
            picture: user.photoURL
          }).catch((error) => {
              console.log(error);
          });
        setSave('Comment Saved');
        setComments('');
        setTimeout(() => { setSave('Comment'); }, 1000);
    }
    useEffect(() => {
        const q = query(collection(db, "public", `${params.userId}`, `posts`, `${params.postId}`, "comments"), orderBy('lastModified', 'desc'), limit(5));
        const load = onSnapshot(q, (snapshot) => {
            // snapshot.docs.map(doc => {
            //     setJournals({...doc.data(), id: doc.id});
            // });
            setJournals(snapshot.docs.map(doc => ({...doc.data(), id: doc.id})));
            setlastvisibility(snapshot.docs[snapshot.docs.length-1]);
        })
        // console.log(posts);
        // setJournals(posts);
        // console.log(journals);
    }, []);
    const scroll = () => {
        const q = query(collection(db, "public", `${params.userId}`, `posts`, `${params.postId}`, "comments"), orderBy('lastModified', 'desc'), startAfter(lastvisibility), limit(5));
        const load = onSnapshot(q, (snapshot) => {
            //setJournals(snapshot.docs.map(doc => ({...doc.data(), id: doc.id})));
            snapshot.docs.map(doc => {
                setJournals(prev => [...prev, {...doc.data(), id: doc.id}]);
                //setposts.extend([{...doc.data(), id: doc.id}]);
            });
            console.log(posts);
            console.log(snapshot.docs);
            //setJournals(posts);
            setlastvisibility(snapshot.docs[snapshot.docs.length-1]);
            //setJournals(snapshot.docs.map(doc => ({...doc.data(), id: doc.id})));
            console.log(lastvisibility);
            
        })
        console.log(journals);
    }
        async function handledelete(id) {
            await deleteDoc(doc(db, `public`, `${params.userId}`, `posts`, `${params.postId}`, "comments", `${id}`));
            console.log("postid", id);
        }
    return (
        <main className="appconts" style={{marginBottom: "30px"}}>
              <Helmet>
                 <title>{database.name}</title>
            </Helmet>              
            <div className='postcontainer'>
                <h1>{database.name}</h1>
                <p className='author'>{database.author}</p>
                {database ? edjsparser.parse(database.entry).map(block =><div className='preview' dangerouslySetInnerHTML={{__html: `${block}` }}></div>): <h2>Loading...</h2>}
                <div className='commentsbox'>
                <h2>Comments:</h2>
                    <textarea className='comment' placeholder='Write a comment...' value={comments} onChange={(e) => setComments(e.target.value)}></textarea>
                    <button className='commentbutton' onClick={HandleSave}>{save}</button>
                </div>

                {journals.map((journal) => (
                    <div className='commentsbox' key={journal.id} id={journal.id}>
                        <h3 style={{fontSize: "25px", display: "inline-block"}}>{journal.author}</h3>
                        {user.uid === journal.uid ? <button onClick={() => handledelete(`${journal.id}`)} className="deletebutton"><MdDelete/> </button> : null}
                        <p className='author'>{journal.lastModified.toDate().toDateString()}</p>
                        <p className='preview'>{ journal.entry }</p>   
                     </div>               
                    ))}  
                <div className="loadmorecomments" onClick={scroll}>
                        Load More Comments
                </div> 
            </div>
        </main>    
    );
}