import { useEffect, useState } from "react";
import './App.css';
import {app} from './Firebase';
import Output from 'editorjs-react-renderer';
import { Link } from "react-router-dom";
import {Helmet} from "react-helmet";
import { getAuth } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { getFirestore, onSnapshot, collection, orderBy, limit, query } from "firebase/firestore"
const edjsHTML = require("editorjs-html");
// function customChecklist(block) {
//   const data = block.data;
//   console.log(data);
// }
const edjsparser = edjsHTML();
const auth = getAuth();
const db = getFirestore(app);
function timeofday() {
  var date = new Date();
  var hour = date.getHours();
  if (hour < 12) {
    return "Good Morning";
  } else if (hour < 18) {
    return "Good Afternoon";
  } else {
    return "Good Evening";
    }
}


export default function Home() {
    const [user] = useAuthState(auth);
    const [journals, setJournals] = useState([]);
    const [publics, setPublic] = useState([]);
    useEffect(() => {
        const q = query(collection(db, "private", `${user.uid}`, "journals"), orderBy('lastModified', 'desc'), limit(5));
        onSnapshot(q, (snapshot) => {
            setJournals(snapshot.docs.map(doc => ({...doc.data(), id: doc.id})));
        })
    }, []);
    useEffect(() => {
      const q = query(collection(db, "public", `${user.uid}`, "posts"), orderBy('lastModified', 'desc'), limit(5));
      onSnapshot(q, (snapshot) => {
        setPublic(snapshot.docs.map(doc => ({...doc.data(), id: doc.id})));
      })
  }, []);
    return (
      <main className='appconts'>
              <Helmet>
                 <title>FlashNotes</title>
                 <meta name="description" content="View all of your public and private FlashNotes" />
            </Helmet>          
        <h1>{timeofday()}</h1>
        <div className='actioncont'>
        <h2>Private Recents</h2>
            <div className="actioncardcont">
                    {journals.map((journal) => (
                    <Link to={`/edit/private/${journal.id}`} className='actioncard' key={journal.id} style={{ textDecoration: 'none' }}>
                        <h2 className="jounrals">{journal.name}</h2>
                        <p className='author'>{journal.author}</p>
                        <p style={{marginLeft: "10px"}}>{journal.lastModified.toDate().toDateString()}</p>
                        {edjsparser.parse(journal.entry).map(test =>
                                <div className='preview' dangerouslySetInnerHTML={{__html: `${test}` }}></div>)}
                     </Link>                    
                    ))}   
                     <Link to={"/writings/private"} style={{ textDecoration: 'none' }} className='actioncard'>
                        <h2>See more</h2>
                        <p className='author'>Click to see more of your files.</p>
                     </Link>                                                                                                                            
            </div>
        </div>
        <div className='actioncont'>
        <h2>Public Recents</h2>
            <div className="actioncardcont">
                    <Link to={"/posts/udS2u9MAo8QV6472qqaSzacY1ww1/8JQUew6uCA1HrlCoUbLj"} style={{ textDecoration: 'none', background: "rgb(5, 118, 224)", color: "white" }} className='actioncard'>
                        <h2 style={{color: "white"}}>Beta Announcement</h2>
                        <p className='author' style={{color: "white"}}>View FlashNotes Announcements</p>
                     </Link> 
                    {publics.map((posts) => (
                    <Link to={`/edit/public/${posts.id}`} className='actioncard' key={posts.id} style={{ textDecoration: 'none' }}>
                        <h2>{posts.name}</h2>
                        <p className='author'>{posts.author}</p>
                        <p style={{marginLeft: "10px"}}>{posts.lastModified.toDate().toDateString()}</p>
                        {/* <Output data={ posts.entry } /> */}
                        {edjsparser.parse(posts.entry).map(block =>
                                <div className='preview' dangerouslySetInnerHTML={{__html: `${block}` }}></div>)}
                     </Link>                    
                    ))}   
                     <Link to={"/writings/public"} style={{ textDecoration: 'none' }} className='actioncard'>
                        <h2>See more</h2>
                        <p className='author'>Click to see more of your files.</p>
                     </Link>                                                                                                                            
            </div>
        </div>


        {/* <div className='actioncont'>
        <h2>Subscriptions</h2>
            <div className='actioncardcont'>
                <div className='actioncard'>
                </div>
            </div>
        </div> */}
      </main>
    );
  }
  