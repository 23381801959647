import { useParams,Link , useLocation} from "react-router-dom";
import { getFirestore, onSnapshot, collection, orderBy, limit, query, startAfter, enableIndexedDbPersistence } from "firebase/firestore"
import { useEffect, useState } from "react";
import './App.css';
import {app} from './Firebase';
import Output from 'editorjs-react-renderer';
import { getAuth } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Helmet } from "react-helmet";
const edjsHTML = require("editorjs-html");
const auth = getAuth();
const db = getFirestore(app);
const edjsparser = edjsHTML();
// enableIndexedDbPersistence(db)
//   .catch((err) => {
//       if (err.code == 'failed-precondition') {
//       } else if (err.code == 'unimplemented') {
//       }
//   });


export default function Drive() {
    const [user] = useAuthState(auth);
    const [journals, setJournals] = useState([]);
    const [lastvisibility, setlastvisibility] = useState({});
    const location = useLocation();
    const [entry, setEntry] = useState('');
    let posts = [];
    let params = useParams();
    let posted = (params.filetype === "private" ) ? "journals" : "posts";
    console.log(posted);
    useEffect(() => {
        const q = query(collection(db, `${params.filetype}`, `${user.uid}`, `${posted}`), orderBy('lastModified', 'desc'), limit(10));
        const load = onSnapshot(q, (snapshot) => {
            console.log(snapshot.docs);
            // snapshot.docs.map(doc => {
            //     posts.push({...doc.data(), id: doc.id});
            // });
            setJournals(snapshot.docs.map(doc => ({...doc.data(), id: doc.id})));
            setlastvisibility(snapshot.docs[snapshot.docs.length-1]);
        })
        console.log(posts);
        setJournals(posts);
        console.log(journals);
    }, [location]);
    const scroll = () => {
        const q = query(collection(db, `${params.filetype}`, `${user.uid}`, `${posted}`), orderBy('lastModified', 'desc'), startAfter(lastvisibility), limit(10));
        const load = onSnapshot(q, (snapshot) => {
            //setJournals(snapshot.docs.map(doc => ({...doc.data(), id: doc.id})));
            snapshot.docs.map(doc => {
                setJournals(prev => [...prev, {...doc.data(), id: doc.id}]);
                //setposts.extend([{...doc.data(), id: doc.id}]);
            });
            console.log(posts);
            console.log(snapshot.docs);
            //setJournals(posts);
            setlastvisibility(snapshot.docs[snapshot.docs.length-1]);
            //setJournals(snapshot.docs.map(doc => ({...doc.data(), id: doc.id})));
            console.log(lastvisibility);
            
        })
        console.log(journals);
    }
    var title = params.filetype.charAt(0).toUpperCase() + params.filetype.slice(1);
    return (
      <main style={{marginTop: "30px"}}>
        <Helmet>
            <title>{title} Journals</title>
        </Helmet>            
        <h2>{title} Journals</h2>
        {journals.map((journal) => (
                    <Link to={`/edit/${params.filetype}/${journal.id}`} className='actioncard' key={journal.id} style={{ textDecoration: 'none' }}>
                        <h2 className="jounrals">{journal.name}</h2>
                        <p className='author'>{journal.author}</p>
                        <p style={{marginLeft: "10px"}}>{journal.lastModified.toDate().toDateString()}</p>
                            {/* <Output data={ journal.entry } /> */}
                            {edjsparser.parse(journal.entry).map(block =>
                                <div className='preview' dangerouslySetInnerHTML={{__html: `${block}` }}></div>)}
                     </Link>                    
                    ))}  
                    <div className="loadmore" onClick={scroll}>
                        Load More
                    </div>                    
      </main>
    );
  }
