import { initializeApp } from "firebase/app";
const firebaseConfig = {
    apiKey: "AIzaSyCy9JS2nnO4FxgyKBxYAsIGgCrxkpQBFXA",
    authDomain: "firemeta-b01bb.firebaseapp.com",
    databaseURL: "https://firemeta-b01bb-default-rtdb.firebaseio.com",
    projectId: "firemeta-b01bb",
    storageBucket: "firemeta-b01bb.appspot.com",
    messagingSenderId: "27244130719",
    appId: "1:27244130719:web:b6d4fbda77304478120daa",
    measurementId: "G-XYKRPKDG1T"
  }
export const app = initializeApp(firebaseConfig);