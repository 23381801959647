import { Link, Outlet } from "react-router-dom";

export default function Writings() {
    return (
      <main className="appconts" style={{marginBottom: "30px"}}>
        <h1>Writings</h1>
        <Link to={`/writings/private`} className="actioncard" style={{ textDecoration: 'none' }}>
          <h2>Private Journals</h2>
          <p className="author">View all of your Private Journals.</p>
        </Link>
        <Link to={`/writings/public`} className="actioncard" style={{ textDecoration: 'none' }}>
          <h2>Public Journals</h2>
          <p className="author">View all of your your Public Journals.</p>
        </Link>
        <Outlet />
      </main>
    );
  }
  