import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Home from './Home';
import Explore from './Explore';
import Writings from './Writings';
import Create from './Create';
import Edit from './Edit';
import Posts from './Posts';
import Drive from './Drive';
import Unknown from './404';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import App from './App';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />}>
        <Route path="home" element={<Home />} />
        <Route path="writings" element={<Writings />}>
          <Route path=":filetype" element={<Drive />} />
        </Route>
        <Route path="explore" element={<Explore />} />
        <Route path="create" element={<Create />} />
        <Route path="edit" element={<Edit />}>
          <Route path=":public/:journalID" element={<Edit />}/>
        </Route>
        <Route path="posts/:userId/:postId" element={<Posts />} />
        <Route path='*' element={<Unknown />} />
        <Route path="" element={<Navigate to="/home" />} />
        </Route>
    </Routes>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
