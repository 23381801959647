import { getFirestore, onSnapshot, collectionGroup } from "firebase/firestore";  
import { Link} from "react-router-dom";
import { useEffect, useState } from "react";
import './App.css';
import {Helmet} from "react-helmet";
import {app} from './Firebase';
import Output from 'editorjs-react-renderer';
import { getAuth } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
const edjsHTML = require("editorjs-html");
const edjsparser = edjsHTML();
const auth = getAuth();
const db = getFirestore(app);


export default function Explore() {
  const [journals, setJournals] = useState([]);
  let posts = [];
  const [lastvisibility, setlastvisibility] = useState({});
  useEffect(() => {
    const q = collectionGroup(db, `posts`);   
    const load = onSnapshot(q, (snapshot) => {
        console.log(snapshot.docs);
        // snapshot.docs.map(doc => {
        //     posts.push({...doc.data(), id: doc.id});
        //     console.log(doc.data());
        // });
        setJournals(snapshot.docs.map(doc => ({...doc.data(), id: doc.id})));
        setlastvisibility(snapshot.docs[snapshot.docs.length-1]);
    })
}, []);
  const scroll = () => {
    const q = collectionGroup(db, `posts`);    
    const load = onSnapshot(q, (snapshot) => {
        snapshot.docs.map(doc => {
            setJournals(prev => [...prev, {...doc.data(), id: doc.id}]);
        });
        setlastvisibility(snapshot.docs[snapshot.docs.length-1]); 
    })
    setJournals(posts);
    console.log(journals);
}
    return (
      <main className='appconts'>
              <Helmet>
                 <title>Explore Journals</title>
                 <meta name="description" content="Explore FlashNote Journals" />
            </Helmet>       
        <h1>Explore</h1>
        {journals.map((journal) => (
                    <Link to={`/posts/${journal.uid}/${journal.id}`} className='actioncard' key={journal.id} style={{ textDecoration: 'none' }}>
                        <h2 className="jounrals">{journal.name}</h2>
                        <p className='author'>{journal.author}</p>
                        <p style={{marginLeft: "10px"}}>{journal.lastModified.toDate().toDateString()}</p>
                        {edjsparser.parse(journal.entry).map(block =>
                                <div className='preview' dangerouslySetInnerHTML={{__html: `${block}` }}></div>)}
                     </Link>                    
                    ))}  
                    <div className="loadmore" onClick={scroll}>
                        Load More
                    </div> 
      </main>
    );
  }
  