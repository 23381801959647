import React from 'react';
import './App.css';
import {app} from './Firebase';
import { Outlet, Link } from "react-router-dom";
import {Signinpage} from './Signin';
import { getAuth, signOut } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
const { initializeAppCheck, ReCaptchaV3Provider } = require("firebase/app-check");
const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6Ld8xaQeAAAAADrXPHQZquPfJ4MR7ziLXnWjpNRW'),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true
});
const auth = getAuth();
const CurrentUser = () => {
  const [user, loading, error] = useAuthState(auth);
  if (error) {
    return (
      <div>
        <p>Error: {error}</p>
      </div>
    );
  }
  if (user) {
    //checknewuser(user.uid);
    return (
      <div className='appcont'>
          <div className='appheader'>
          <h1 style={{display: "inline-block", color: "rgb(5, 118, 224)", marginTop: "10px", marginLeft: "10px"}}>FlashNotes</h1>
          <div className='appbar'>
            <Link to="/home" className='apps' style={{ textDecoration: 'none' }}> Home</Link>
            <Link to="/writings" className='apps' style={{ textDecoration: 'none' }}> Writings</Link>
            <Link to="/explore" className='apps' style={{ textDecoration: 'none' }}> Explore</Link>
          </div>
          <div style={{float: "right", position: "relative" }}>
            <div onClick={signout} className='profilecircle' style={{background: `url(${user.photoURL}) center/cover`}}></div>
            <Link className='createbutton' to="/create" style={{ textDecoration: 'none' }}>Create</Link>
          </div>
          </div> 
          <Outlet />
      </div>
    );
  }
  return <Signinpage />;
};
function signout() {
  signOut(auth).then(() => {

  }).catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
  });
}
function App() {
  return (
    <div className="App">
      <header> 

      </header>
      <section>
        <CurrentUser />
      </section> 
    </div>
  );
}
export default App;
