import EditorJs from 'react-editor-js'
import { useState } from "react";
import './App.css';
import {Helmet} from "react-helmet";
import {app} from './Firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { getFirestore, Timestamp, addDoc, collection } from "firebase/firestore"
import { getAuth } from 'firebase/auth';
import Paragraph from '@editorjs/paragraph'
import nestedList from '@editorjs/nested-list'
import Code from '@editorjs/code'
import Header from '@editorjs/header'
import Quote from '@editorjs/quote'
import Marker from '@editorjs/marker'
import CheckList from '@editorjs/checklist'
import Delimiter from '@editorjs/delimiter'
import InlineCode from '@editorjs/inline-code'
import Underline from '@editorjs/underline';
import React from 'react';


const auth = getAuth();
const db = getFirestore(app);
//const ReactEditorJS = createReactEditorJS();
export const EDITOR_JS_TOOLS = {
    list: nestedList,
    code: Code,
    header: Header,
    quote: Quote,
    marker: Marker,
    // checklist: CheckList,
    delimiter: Delimiter,
    underline: Underline,
    inlineCode: InlineCode,
  }
//   const upload = React.useCallback(() => {
//     const savedData = await editorCore.current.save();
//   }, [])
export default function Create() {
    const [title, setTitle] = useState("");
    const [save, setSave] = useState("Save");
    const [user] = useAuthState(auth);
    const instanceRef = React.useRef(null);
    const [privacy, setPrivacy] = useState('Private');
    const [privacyset, setPrivacyset] = useState('true');

    function Private(input) {
        if (input == false) {
            setPrivacy("Public");
            setPrivacyset(false);
        }
        else {
            setPrivacy("Private");
            setPrivacyset(true);
        }
    }
    async function HandleSave() {
        const savedData = await instanceRef.current.save();
        if (privacy == "Private") {
            const newdocRef = addDoc(collection(db, "private", `${user.uid}`, "journals"), {
              name: title,
              entry: savedData,
              lastModified: Timestamp.now(),
              author: user.displayName,
              uid: user.uid,
              picture: user.photoURL,
              user: user.uid
            }).catch((error) => {
                console.log(error);
            });
            console.log('privatepublished');
            setSave("Journal Saved");
            setTimeout(() => { window.location.href = "/home";}, 500);
           
            
        }
        else if (privacy == "Public") {
            const newdocRef = addDoc(collection(db, "public", `${user.uid}`, "posts"), {
              name: title,
              entry: savedData,
              lastModified: Timestamp.now(),
              author: user.displayName,
              uid: user.uid,
              picture: user.photoURL,
              user: user.uid
            }).catch((error) => {
                console.log(error);
            });
            console.log('publicpublished');
            setSave("Journal Saved");
            setTimeout(() => { window.location.href = "/home";}, 500);
        }
        else {
            console.log("something went wrong");
        }
    }
    console.log(privacy);
    console.log(title);
    const button ={
        height: "50px",
        display: "block",
        border: "none",
        outline: "none",
        width: "250px",
        transition: ".25s",
        backgroundColor: "#4285F4",
        borderRadius: "10px",
        marginTop: "20px",
        fontSize: "20px",
        color: "white" 
    };
    const button2 ={
      height: "30px",
      display: "inline-block",
      border: "none",
      outline: "none",
      margin: "10px",
      width: "fit-content",
      transition: ".25s",
      backgroundColor: "#4285F4",
      borderRadius: "10px",
      marginTop: "20px",
      fontSize: "20px",
      color: "white" 
  };

    class Toggle extends React.Component {
        constructor(props) {
          super(props);
          this.state = {isPrivate: true};
          this.handleClick = this.handleClick.bind(this);
        }
      
        handleClick() {
          this.setState(prevState => ({
            isPrivate: !prevState.isPrivate
          }));
          Private(this.state.isPrivate)
        }
        render() {
          return (
            <button className="setbutton" onClick={this.handleClick} style={button}>
              {privacy}
            </button>
          );
        }
      }
      class Toggle2 extends React.Component {
        constructor(props) {
          super(props);
          this.state = {isPrivate: true};
          this.handleClick = this.handleClick.bind(this);
        }
      
        handleClick() {
          this.setState(prevState => ({
            isPrivate: !prevState.isPrivate
          }));
          Private(this.state.isPrivate)
        }
        render() {
          return (
            <button className="setbutton" onClick={this.handleClick} style={button2}>
              {privacy}
            </button>
          );
        }
      }

    return (
      <main className="appconts createcont">
        <div className="sidepanelleft">
            <Helmet>
                 <title>Create</title>
                 <meta name="description" content="Creating a FlashNote" />
            </Helmet>          
            <div className='sidepanel'>
            <h2 style={{marginTop: "0px"}}>Settings</h2>
            <p>Post Visibility:</p>
            <Toggle/>
            {privacyset ? <p>Your journal will remain private.</p> : <p>This journal will be published under your account name.</p>}
            <div className='divider'></div>
            <button className="setbutton" style={button} onClick={HandleSave}>{save}</button>
            </div>
        </div>
        <div className='headerbar'>
        <form>
            <input className="titleinput" placeholder="Title" value={title} onChange={(e) => setTitle(e.target.value)}></input>
        </form>
        <div className="mobilesave">
        <Toggle2/>
        <button className="setbutton" style={button2} onClick={HandleSave}>{save}</button>
        </div>
        <EditorJs instanceRef={instance => (instanceRef.current = instance)} tools={EDITOR_JS_TOOLS} style={{width: "100vw"}}/>
        </div>
        
        <div className="sidepanelright"></div>
      </main>
    );
  }
  